import baseApiSlice from "./baseApiSlice";

export const netlifyApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    addToUserCollection: build.mutation({
      async onQueryStarted({ artwork, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          // optimistic update
          netlifyApiSlice.util.updateQueryData("fetchUserCollection", userId, (draft) => {
            draft.push(artwork);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(netlifyApiSlice.util.invalidateTags(["user-collection"]));
        }
      },
      query: ({ artwork }) => ({
        apiRequirements: ["token"],
        method: "POST",
        url: `${process.env.GATSBY_NETLIFLY_API_HOST}/.netlify/functions/create-user-collection?artwork=${artwork._id}`,
      }),
    }),
    fetchUserCollection: build.query({
      providesTags: ["user-collection"],
      query: (userId) => ({
        url: `${process.env.GATSBY_NETLIFLY_API_HOST}/.netlify/functions/get-user-collection?userId=${userId}`,
      }),
      transformResponse: (payload) => (payload[0]?.artwork ? payload[0].artwork : []),
    }),
    removeFromUserCollection: build.mutation({
      async onQueryStarted({ artwork, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          // optimistic update
          netlifyApiSlice.util.updateQueryData("fetchUserCollection", userId, (draft) => draft.filter(({ _id }) => _id !== artwork._id)),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(netlifyApiSlice.util.invalidateTags(["user-collection"]));
        }
      },
      query: ({ artwork }) => ({
        apiRequirements: ["token"],
        method: "POST",
        url: `${process.env.GATSBY_NETLIFLY_API_HOST}/.netlify/functions/delete-artwork-from-user-collection?artwork=${artwork._id}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddToUserCollectionMutation,
  useRemoveFromUserCollectionMutation,
  useFetchUserCollectionQuery,
} = netlifyApiSlice;
