import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const GetInternalLinkContext = ({ id, slug }) => {
  const data = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          id
          path
          pageContext
        }
      }
    }
  `);

  const pages = data.allSitePage.nodes;

  let result = null;

  const { language } = useI18next();

  if (slug) {
    result = pages.find((page) => page.path.replace(";", "-") === slug.replace(";", "-"));
  } else {
    result = pages.find((page) => page.pageContext.id === id);
  }

  const path = result?.path || (language === "en-GB" ? "/missingtranslation" : "/fehlendeuebersetzung");

  return {
    path: `${path.replace(";", "-")}` || "/",
    title: result?.pageContext?.title || "",
  };
};

export default GetInternalLinkContext;
