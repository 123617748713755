import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ArtworkCardComponent from "../../components/ArtworkCard";

const ArtworkCard = ({ data, cta, className }) => ArtworkCardComponent(data, cta, className);

export const query = graphql`
  fragment ArtworkCard on SanityArtwork {
    id
    slug {
      current
    }
    title
    year
    artist {
      name
    }
    images {
      ...ImageWithPreview
      alt
    }
  }
`;

ArtworkCard.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.node,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ArtworkCard.defaultProps = {
  className: "",
  cta: null,
};

export default ArtworkCard;
