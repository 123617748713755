import React from "react";

import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Section from "../../components/Section/Section";
import { useFetchUserCollectionQuery } from "../../features/api/netlifyApiSlice";
import ArtworkCard from "../Artwork/ArtworkCard";
import MainLayout from "../layouts/MainLayout";

// eslint-disable-next-line import/order
import { useTranslation } from "gatsby-plugin-react-i18next";
// eslint-disable-next-line import/order
import { useLocation } from "@reach/router";

const UserCollectionSharePage = () => {
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("id");

  const userCollections = useFetchUserCollectionQuery(userId, {
    skip: userId === null,
  });

  const { t } = useTranslation();

  return (
    <MainLayout>
      <Section>
        {userCollections.isLoading && (
          <div className="px-18 grid gap-30 grid-cols-2 lg:grid-cols-3 lg:gap-60">
            <div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
              <h5><Skeleton width={170} /></h5>
              <Skeleton height={40} />
            </div>
            <div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
              <h5><Skeleton width={170} /></h5>
              <Skeleton height={40} />
            </div>
            <div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
              <h5><Skeleton width={170} /></h5>
              <Skeleton height={40} />
            </div>
            <div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
              <h5><Skeleton width={170} /></h5>
              <Skeleton height={40} />
            </div>
            <div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
              <h5><Skeleton width={170} /></h5>
              <Skeleton height={40} />
            </div>
            <div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
              <h5><Skeleton width={170} /></h5>
              <Skeleton height={40} />
            </div>
            <div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={250} /></div>
              <div className="text-body2 mb-3 mt-18"><Skeleton height={18} width={100} /></div>
              <h5><Skeleton width={170} /></h5>
              <Skeleton height={40} />
            </div>
          </div>
        )}

        {userCollections.isSuccess && userCollections.data.length !== 0 && (
          <div className="px-18 grid gap-30 grid-cols-2 lg:grid-cols-3 lg:gap-60">
            {userCollections.data.map((artwork, index) => (
              <ArtworkCard
                key={artwork.id}
                data={artwork}
                className={classNames({ "mt-60": index === 0 || index === 2 })}
              />
            ))}
          </div>
        )}
      </Section>
    </MainLayout>
  );
};

export default UserCollectionSharePage;
